/* color palette (dark - light) #224a6b #465866 #7b99b1 #9ec5e6 #d2dfea #ffffff */

* {
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  color: #465866;
  text-align: center;
  line-height: 1.5;
  margin: 0 2%;
}

main {
  margin-bottom: 10%;
}

nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 2%;
  padding: 1% 0;
  background-color: #9ec5e6;
}

header {
  padding: 12% 0;
}

section {
  min-height: 200px;
  padding: 6% 0;
  color: white;
  background-color: #224a6b;
}

section:nth-child(even) {
  background-color: #7b99b1;
}

ul {
  padding: 0;
}

.nav-bar {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 1% auto;
}
.nav-bar > li {
  list-style-type: none;
  color: white;
}

.nav-bar > li > a {
  flex-direction: column;
  font-size: 10px;
}

.hero {
  height: 150px;
  border: 4px solid #d2dfea;
  border-radius: 100%;
}

.skills-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skills-list > li {
  list-style: none;
  padding: 0 2%;
}

.project-sources {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
}

.project-sources > li {
  list-style-type: none;
}

.project-link > a {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.project-img {
  width: 300px;
  height: 200px;
}

.project-tools {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.contact-list-item {
  list-style-type: none;
  font-size: 16px;
}

.bio {
  text-align: left;
  padding: 0 4%;
}

a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: white;
  text-align: center;
}

.contact-list-item > a {
  padding: 2% 8%;
}
.contact-list-item > a > i {
  padding-right: 2%;
}

i.scroll-top {
  position: fixed;
  bottom: 10%;
  right: 4%;
  color: #224a6b;
  background-color: white;
  font-size: 24px;
  border: 0px solid white;
  border-radius: 100%;
  box-shadow: 2px 2px 4px grey;
}

.hidden {
  display: none !important;
}

@media screen and (min-width: 520px) {
  main {
    margin-bottom: 7%;
  }

  body {
    font-size: 24px;
  }

  .nav-bar > li > a {
    flex-direction: column;
    font-size: 12px;
  }

  .contact-list-item {
    font-size: 24px;
  }

  .project-sources {
    justify-content: space-evenly;
  }

  .project-link > a {
    font-size: 12px;
  }

  i.material-icons {
    font-size: 36px;
  }

  .project-img {
    width: 500px;
    height: 350px;
  }

  .project-tools {
    font-size: 36px;
  }
}

@media screen and (min-width: 1024px) {
  body {
    margin: 0;
  }

  main {
    margin-top: 8%;
    margin-bottom: 0;
  }

  header {
    padding: 6% 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .hero {
    height: 225px;
    margin: 0 1%;
  }

  nav {
    position: fixed;
    bottom: unset;
    top: 0;
    margin: 0;
  }

  .nav-bar > li > a {
    flex-direction: row;
    font-size: 24px;
  }

  i.material-icons {
    margin-right: 4%;
  }

  .contact {
    margin: 0 30%;
  }

  .project-sources {
    justify-content: space-evenly;
  }

  .project-img {
    width: 800px;
    height: 575px;
  }

  i.scroll-top {
    font-size: 48px;
    cursor: pointer;
  }
}
